import { InfiniteTable as UIKitTable } from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { BITMASK_UTILS } from "@fm-frontend/utils";
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { useMemo, useState } from "react";
import styled from "styled-components";
import Gap from "~components/Gap";
import DateTimeViewer from "~components/Table/Cell/DateTimeViewer";
import { EllipsisCellTheme } from "~components/Table/CellTheme/Ellipsis";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import Search from "~components/Table/Options/Search";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { DATE_TIME_FORMAT } from "~constants/date";
import { AccessFlagBitmask, User } from "~entities/user";
import { useUsersApi } from "~hooks/api/useUsersApi";
import { useClientId } from "~hooks/useClientId";
import AccessCell from "~pages/UsersAndRoles/AccessCell";
import ActionsCell from "~pages/UsersAndRoles/ActionsCell";
import EmailCell from "~pages/UsersAndRoles/EmailCell";
import NameCell from "~pages/UsersAndRoles/NameCell";
import { sortTimestamp } from "~utils/sortTimestamp";

const Table = styled(UIKitTable<User>)`
    min-width: 1010px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

const columnHelper = createColumnHelper<User>();

const usersAndRolesTableColumns = [
    columnHelper.accessor("name", {
        header: "Name",
        cell: (info) => (
            <EllipsisCellTheme maxWidth={300}>
                <NameCell value={info.getValue()} owner={info.row.original.owner} />
            </EllipsisCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "300px",
            },
        },
    }),
    columnHelper.accessor("email", {
        header: "Email",
        cell: (info) => (
            <EllipsisCellTheme maxWidth={270}>
                <EmailCell value={info.getValue()} />
            </EllipsisCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "270px",
            },
        },
    }),
    columnHelper.accessor("accessFlags", {
        id: "trading",
        header: "Trading",
        cell: (info) => (
            <AccessCell value={info.getValue()} bitmask={AccessFlagBitmask.AllowTrading} />
        ),
        meta: {
            headerStyleProps: {
                width: "60px",
            },
        },
    }),
    columnHelper.accessor("accessFlags", {
        id: "risks",
        header: "Risks",
        cell: (info) => (
            <AccessCell value={info.getValue()} bitmask={AccessFlagBitmask.AllowLimitsManagement} />
        ),
        meta: {
            headerStyleProps: {
                width: "60px",
            },
        },
    }),
    columnHelper.accessor("accessFlags", {
        id: "settle",
        header: "Settle",
        cell: (info) => (
            <AccessCell value={info.getValue()} bitmask={AccessFlagBitmask.AllowSettlements} />
        ),
        meta: {
            headerStyleProps: {
                width: "60px",
            },
        },
    }),
    columnHelper.accessor("accessFlags", {
        id: "api",
        header: "API",
        cell: (info) => (
            <AccessCell value={info.getValue()} bitmask={AccessFlagBitmask.AllowApiKeyManagement} />
        ),
        meta: {
            headerStyleProps: {
                width: "60px",
            },
        },
    }),
    columnHelper.accessor("accessFlags", {
        id: "user",
        header: "User",
        cell: (info) => (
            <AccessCell value={info.getValue()} bitmask={AccessFlagBitmask.AllowUserManagement} />
        ),
        meta: {
            headerStyleProps: {
                width: "60px",
            },
        },
    }),
    columnHelper.accessor("accessFlags", {
        id: "sub-account",
        header: "Sub-account",
        cell: (info) => (
            <AccessCell
                value={info.getValue()}
                bitmask={AccessFlagBitmask.AllowSubaccountsManagement}
            />
        ),
        meta: {
            headerStyleProps: {
                width: "60px",
            },
        },
    }),
    columnHelper.accessor("createdAt", {
        header: "Created",
        sortingFn: sortTimestamp,
        cell: (info) => <DateTimeViewer value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "90px",
            },
        },
    }),
    columnHelper.display({
        id: "actions",
        cell: (info) => <ActionsCell user={info.row.original} />,
        enableSorting: false,
        meta: {
            cellStyleProps: {
                style: {
                    textAlign: "right",
                },
            },
        },
    }),
];

const isMatch = (user: User, query: string) => {
    const { name, email } = user;

    return (
        name.toUpperCase().includes(query.toUpperCase()) ||
        email.toUpperCase().includes(query.toUpperCase())
    );
};

export const UsersAndRolesPage = () => {
    const clientId = useClientId();
    const { data = EMPTY_ARRAY, isLoading } = useUsersApi(clientId);
    const [sorting, setSorting] = useState<SortingState>([]);
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;

    const users = useMemo(
        () => (query === "" ? data : data.filter((user) => isMatch(user, query))),
        [query, data],
    );
    const getExportData = () =>
        users.map((user) => ({
            Name: user.name,
            Email: user.email,
            Trading: BITMASK_UTILS.isBitKeyApplied(user.accessFlags, AccessFlagBitmask.AllowTrading)
                ? "Yes"
                : "No",
            Risks: BITMASK_UTILS.isBitKeyApplied(
                user.accessFlags,
                AccessFlagBitmask.AllowLimitsManagement,
            )
                ? "Yes"
                : "No",
            Settle: BITMASK_UTILS.isBitKeyApplied(
                user.accessFlags,
                AccessFlagBitmask.AllowSettlements,
            )
                ? "Yes"
                : "No",
            API: BITMASK_UTILS.isBitKeyApplied(
                user.accessFlags,
                AccessFlagBitmask.AllowApiKeyManagement,
            )
                ? "Yes"
                : "No",
            User: BITMASK_UTILS.isBitKeyApplied(
                user.accessFlags,
                AccessFlagBitmask.AllowUserManagement,
            )
                ? "Yes"
                : "No",
            "Sub-account": BITMASK_UTILS.isBitKeyApplied(
                user.accessFlags,
                AccessFlagBitmask.AllowSubaccountsManagement,
            )
                ? "Yes"
                : "No",
            Created: format(user.createdAt * 1000, DATE_TIME_FORMAT),
        }));

    return (
        <TableContext.Provider value={tableContextValue}>
            <OptionsContainer>
                <Search query={query} onChange={setQuery} />
                <Gap />
                <ExportButton
                    data={getExportData}
                    filename={`users-and-roles_${clientId}`}
                    loading={isLoading}
                    disabled={users.length === 0}
                />
            </OptionsContainer>
            <Table
                tableOptions={{
                    data: users,
                    columns: usersAndRolesTableColumns,
                    state: {
                        sorting,
                    },
                    onSortingChange: setSorting,
                    getCoreRowModel: getCoreRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                }}
                isLoading={isLoading}
            />
        </TableContext.Provider>
    );
};
