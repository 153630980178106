import { InfiniteTable as UIKitTable, P } from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { useMemo, useState } from "react";
import styled from "styled-components";
import Gap from "~components/Gap";
import DateTimeViewer from "~components/Table/Cell/DateTimeViewer";
import { EllipsisCellTheme } from "~components/Table/CellTheme/Ellipsis";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import Search from "~components/Table/Options/Search";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { DATE_TIME_FORMAT } from "~constants/date";
import { Key } from "~entities/keys";
import { useKeysApi } from "~hooks/api/useKeysApi";
import ActionsCell from "~pages/MyAdminKeys/ActionsCell";
import { AddButton } from "~pages/MyAdminKeys/AddButton";
import { TagCell } from "~pages/MyAdminKeys/TagCell";
import { sortTimestamp } from "~utils/sortTimestamp";

const Table = styled(UIKitTable<Key>)`
    min-width: 1010px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

const columnHelper = createColumnHelper<Key>();

const myAdminKeysTableColumns = [
    columnHelper.accessor("createdAt", {
        header: "Created",
        sortingFn: sortTimestamp,
        cell: (info) => <DateTimeViewer value={info.getValue() / 1000} />,
        meta: {
            headerStyleProps: {
                width: "90px",
            },
        },
    }),
    columnHelper.accessor("tag", {
        header: "Tag",
        cell: (info) => (
            <EllipsisCellTheme maxWidth={300}>
                <TagCell value={info.getValue()} />
            </EllipsisCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "300px",
            },
        },
    }),
    columnHelper.accessor("allowWrite", {
        header: "Write",
        cell: (info) => <P>{info.getValue() ? "Y" : "N"}</P>,
        meta: {
            headerStyleProps: {
                width: "60px",
            },
        },
    }),
    columnHelper.accessor("key", {
        header: "Key",
        cell: (info) => (
            <EllipsisCellTheme maxWidth={400}>
                <P>{info.getValue()}</P>
            </EllipsisCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "400px",
            },
        },
    }),
    columnHelper.display({
        id: "actions",
        cell: (info) => <ActionsCell data={info.row.original} />,
        enableSorting: false,
        meta: {
            cellStyleProps: {
                style: {
                    textAlign: "right",
                },
            },
        },
    }),
];

const isMatch = (data: Key, query: string) => {
    const { tag } = data;

    return tag.toUpperCase().includes(query.toUpperCase());
};

export const MyAdminKeysPage = () => {
    const { data = EMPTY_ARRAY, isLoading } = useKeysApi();
    const [sorting, setSorting] = useState<SortingState>([]);
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;

    const filteredData = useMemo(
        () => (query === "" ? data : data.filter((item) => isMatch(item, query))),
        [query, data],
    );
    const getExportData = () =>
        filteredData.map((item) => ({
            Created: format(item.createdAt, DATE_TIME_FORMAT),
            Tag: item.tag,
            "Allow write": item.allowWrite ? "Y" : "N",
            Key: item.key,
        }));

    return (
        <TableContext.Provider value={tableContextValue}>
            <OptionsContainer>
                <Search query={query} onChange={setQuery} />
                <Gap />
                <ExportButton
                    data={getExportData}
                    filename="my-admin-keys"
                    loading={isLoading}
                    disabled={filteredData.length === 0}
                />
                <AddButton />
            </OptionsContainer>
            <Table
                tableOptions={{
                    data: filteredData,
                    columns: myAdminKeysTableColumns,
                    state: {
                        sorting,
                    },
                    onSortingChange: setSorting,
                    getCoreRowModel: getCoreRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                }}
                isLoading={isLoading}
            />
        </TableContext.Provider>
    );
};
