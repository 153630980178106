import { InfiniteTable as UIKitTable } from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { FC, useMemo, useState } from "react";
import styled from "styled-components";
import Gap from "~components/Gap";
import DateTimeViewer from "~components/Table/Cell/DateTimeViewer";
import { EllipsisCellTheme } from "~components/Table/CellTheme/Ellipsis";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import Search from "~components/Table/Options/Search";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { DATE_TIME_FORMAT } from "~constants/date";
import { ClientType } from "~entities/client";
import { ReferralStatus } from "~entities/referral";
import { SubaccountStatus, SubaccountType } from "~entities/subaccount";
import { useReferralsApi } from "~hooks/api/useReferralsApi";
import { useSubaccountsApi } from "~hooks/api/useSubaccountsApi";
import { useClientId } from "~hooks/useClientId";
import { ActionsCell } from "~pages/Subaccounts/ActionsCell";
import { EmailCell } from "~pages/Subaccounts/EmailCell";
import { IdCell } from "~pages/Subaccounts/IdCell";
import { NameCell } from "~pages/Subaccounts/NameCell";
import { StatusCell } from "~pages/Subaccounts/StatusCell";
import { TypeCell } from "~pages/Subaccounts/TypeCell";
import { getSubaccountStatusTitle } from "~pages/Subaccounts/utils";
import { getSubaccountTypeTitle } from "~utils/getSubaccountTypeTitle";
import { sortTimestamp } from "~utils/sortTimestamp";

type TableData = {
    clientId?: number;
    username: string;
    email: string;
    type: ClientType;
    status: ReferralStatus | SubaccountStatus;
    createdAt: number;
};

const Table = styled(UIKitTable<TableData>)`
    min-width: 1010px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

const columnHelper = createColumnHelper<TableData>();

const subaccountsTableColumns = [
    columnHelper.display({
        id: "clientId",
        header: "Client ID",
        cell: (info) => <IdCell value={info.row.original.clientId} />,
        meta: {
            headerStyleProps: {
                width: "60px",
            },
        },
    }),
    columnHelper.accessor("username", {
        header: "Name",
        cell: (info) => (
            <EllipsisCellTheme maxWidth={300}>
                <NameCell value={info.getValue()} />
            </EllipsisCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "300px",
            },
        },
    }),
    columnHelper.accessor("email", {
        header: "Email",
        cell: (info) => (
            <EllipsisCellTheme maxWidth={300}>
                <EmailCell value={info.getValue()} />
            </EllipsisCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "300px",
            },
        },
    }),
    columnHelper.accessor("type", {
        header: "Type",
        cell: (info) => <TypeCell value={info.getValue()} />,
    }),
    columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => <StatusCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "85px",
            },
        },
    }),
    columnHelper.accessor("createdAt", {
        header: "Created",
        sortingFn: sortTimestamp,
        cell: (info) => <DateTimeViewer value={info.getValue() / 1000} />,
        meta: {
            headerStyleProps: {
                width: "90px",
            },
        },
    }),
    columnHelper.display({
        id: "actions",
        cell: (info) => <ActionsCell clientId={info.row.original.clientId} />,
        enableSorting: false,
        meta: {
            headerStyleProps: {
                width: "65px",
            },
            cellStyleProps: {
                style: {
                    textAlign: "right",
                },
            },
        },
    }),
];

const convertType = (subaccountType: SubaccountType) => {
    switch (subaccountType) {
        case SubaccountType.Maker:
            return ClientType.Maker;
        case SubaccountType.Taker:
            return ClientType.Taker;
        case SubaccountType.PrimeBroker:
            return ClientType.PrimeBroker;
        default:
            throw Error("Undefined subaccount type");
    }
};

const isMatch = (data: TableData, query: string) => {
    const { clientId, username, email } = data;
    const normalizedQuery = query.toUpperCase();

    return (
        String(clientId).startsWith(normalizedQuery) ||
        username.toUpperCase().includes(normalizedQuery) ||
        email.toUpperCase().includes(normalizedQuery)
    );
};

export const SubaccountsPage: FC = () => {
    const clientId = useClientId();
    const { data: subaccounts = EMPTY_ARRAY, isLoading: isSubaccountsLoading } =
        useSubaccountsApi(clientId);
    const { data: referrals = EMPTY_ARRAY, isLoading: isReferralsLoading } =
        useReferralsApi(clientId);
    const [sorting, setSorting] = useState<SortingState>([{ id: "createdAt", desc: true }]);
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;

    const isLoading = isSubaccountsLoading || isReferralsLoading;

    const data = useMemo(
        () => [
            ...subaccounts
                .filter(({ status }) =>
                    [SubaccountStatus.Active, SubaccountStatus.Disabled].includes(status),
                )
                .map<TableData>((subaccount) => ({
                    clientId: subaccount.info.clientId,
                    username: subaccount.info.username,
                    email: subaccount.info.email,
                    type: convertType(subaccount.type),
                    status: subaccount.status,
                    createdAt: subaccount.createdAt,
                })),
            ...referrals
                .filter(
                    ({ isSubaccount, status }) =>
                        isSubaccount &&
                        [ReferralStatus.Sent, ReferralStatus.Expired].includes(status),
                )
                .map<TableData>((referral) => ({
                    clientId: referral.clientId === 0 ? undefined : referral.clientId,
                    username: referral.name,
                    email: referral.email,
                    type: referral.clientType,
                    status: referral.status,
                    createdAt: referral.createdAt,
                })),
        ],
        [subaccounts, referrals],
    );

    const filteredData = useMemo(
        () => (query === "" ? data : data.filter((item) => isMatch(item, query))),
        [query, data],
    );
    const getExportData = () =>
        filteredData.map((item) => ({
            "Client ID": item.clientId ?? "–",
            Name: item.username,
            Email: item.email,
            Type: getSubaccountTypeTitle(item.type),
            Status: getSubaccountStatusTitle(item.status),
            Created: format(item.createdAt, DATE_TIME_FORMAT),
        }));

    return (
        <TableContext.Provider value={tableContextValue}>
            <OptionsContainer>
                <Search query={query} onChange={setQuery} />
                <Gap />
                <ExportButton
                    data={getExportData}
                    filename={`subaccounts_${clientId}`}
                    loading={isLoading}
                    disabled={subaccounts.length === 0}
                />
            </OptionsContainer>
            <Table
                tableOptions={{
                    data: filteredData,
                    columns: subaccountsTableColumns,
                    state: {
                        sorting,
                    },
                    onSortingChange: setSorting,
                    getCoreRowModel: getCoreRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                }}
                isLoading={isLoading}
            />
        </TableContext.Provider>
    );
};
