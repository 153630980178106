import { getEnv } from "@fm-frontend/utils";

declare global {
    interface Window {
        ENV: {
            REACT_APP_REGION: string;
            REACT_APP_IDENTITY_POOL_REGION: string;
            REACT_APP_USER_POOL_ID: string;
            REACT_APP_USER_POOL_WEBCLIENT_ID: string;
            REACT_APP_API_URL: string;
            REACT_APP_WS_URL: string;
            REACT_APP_FM_API_URL: string;
        };
    }
}

export const prodHost = "app.admin.trade.finerymarkets.com";

const isProd = prodHost === window.location.host;

export const apiUrl = `${
    getEnv().REACT_APP_API_URL ? `https://${getEnv().REACT_APP_API_URL}` : window.location.origin
}/api`;
export const onboardingApiUrl = `${
    getEnv().REACT_APP_FM_API_URL
        ? `https://${getEnv().REACT_APP_FM_API_URL}`
        : window.location.origin
}/api/onboarding/admin`;
export const settingsApiUrl = `${
    getEnv().REACT_APP_API_URL ? `https://${getEnv().REACT_APP_API_URL}` : window.location.origin
}/api/settings/admin`;
export const hrpReportingApiUrl = `${apiUrl}/hrpreporting`;
export const pulseAnalyticsUrl = isProd
    ? "https://admin.pulse.finerymarkets.com/analytics"
    : "https://admin.pulse.dev.finerymarkets.com/analytics";
export const wsUrl = `${getEnv().REACT_APP_WS_URL}/ws`;
