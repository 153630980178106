import { useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { ClientId } from "~entities/client";
import { ClientInfo } from "~entities/clientInfo";
import useAppDispatch from "~hooks/useAppDispatch";
import { fetchClientInfo, setClientInfo } from "~store/clientsInfo/actions";
import { requestAdminery } from "~utils/api";

type RawClientInfoEntity = [
    id: ClientId,
    username: string,
    takerFeeMarkup: number,
    flags: number,
    createdAt: number,
    fullName: string,
    email: string,
    companyName: string,
    registrationNumber: string,
    address: string,
    city: string,
    zipCode: string,
    country: string,
    makerFee: number,
    fineryShare: number,
    agreementNr: string,
    primeBrokerId: ClientId,
    signatory: string,
    signatoryPosition: string,
    signedAt: number,
];

const prepareClientInfo = ([
    id,
    username,
    takerFeeMarkup,
    flags,
    createdAt,
    fullName,
    email,
    companyName,
    registrationNumber,
    address,
    city,
    zipCode,
    country,
    makerFee,
    fineryShare,
    agreementNr,
    primeBrokerId,
    signatory,
    signatoryPosition,
    signedAt,
]: RawClientInfoEntity): ClientInfo => ({
    id,
    username,
    takerFeeMarkup,
    flags,
    createdAt,
    fullName,
    email,
    companyName,
    registrationNumber,
    address,
    city,
    zipCode,
    country,
    makerFee,
    fineryShare,
    agreementNr,
    primeBrokerId,
    signatory,
    signatoryPosition,
    signedAt,
});

const useClientInfoApi = (clientId: ClientId) => {
    const { showBoundary } = useErrorBoundary();
    const dispatch = useAppDispatch();

    useEffect(() => {
        (async () => {
            dispatch(fetchClientInfo({ id: clientId }));

            try {
                const data: RawClientInfoEntity = await requestAdminery("clientInfo", {
                    id: clientId,
                });

                dispatch(setClientInfo({ clientInfo: prepareClientInfo(data) }));
            } catch (error) {
                showBoundary(error);
            }
        })();
    }, [clientId, dispatch, showBoundary]);
};

export default useClientInfoApi;
