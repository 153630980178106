import { useEffect, useMemo } from "react";
import styled from "styled-components";
import { ClientType } from "~entities/client";
import useClientInfoApi from "~hooks/api/useClientInfoApi";
import { useClientsApi } from "~hooks/api/useClientsApi";
import { useHRPClientsApi } from "~hooks/api/useHRPClientsApi";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import { ProfileContext, useProfileContextValue } from "~pages/Profile/Context";
import MakerProfile from "~pages/Profile/MakerProfile";
import TakerProfile from "~pages/Profile/TakerProfile";
import { getClient } from "~store/clients/selectors";
import { getClientInfo } from "~store/clientsInfo/selectors";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 1010px;
`;

export const ProfilePage = () => {
    const clientId = useClientId();

    useClientsApi();
    useClientInfoApi(clientId);
    const { data: hrpClients = {} } = useHRPClientsApi();

    const profileContextValue = useProfileContextValue();

    const client = useAppSelector((state) => getClient(state)(clientId));
    const clientInfo = useAppSelector((state) => getClientInfo(state)(clientId));
    const hrpId = useMemo(() => hrpClients[clientId]?.hrpClientId, [hrpClients, clientId]);

    useEffect(() => {
        profileContextValue.setClient(client);
    }, [client, profileContextValue]);

    useEffect(() => {
        profileContextValue.setClientInfo(clientInfo);
    }, [clientInfo, profileContextValue]);

    useEffect(() => {
        profileContextValue.setHRPId(hrpId);
    }, [hrpId, profileContextValue]);

    return (
        <Container>
            <ProfileContext.Provider value={profileContextValue}>
                {client?.type === ClientType.Maker && <MakerProfile />}
                {client?.type === ClientType.Taker && <TakerProfile clientId={clientId} />}
                {client?.type === ClientType.PrimeBroker && <TakerProfile clientId={clientId} />}
            </ProfileContext.Provider>
        </Container>
    );
};
